<template>
  <ion-content class="ion-padding caution">
    <table>
      <tr>
        <td width="25%" valign="top">
          <div style="padding: 10px">
            <img src="../../../public/assets/icon/exclamacion.svg" />
          </div>
        </td>
        <td>
          <div style="padding: 10px 0 5px 10px">
            <span class="cautionTitle"> Cambiar plan </span>
          </div>

          <div style="padding: 0 10px">
            <span class="cautionContent">
              Cambiar tu plan reemplazará tu plan actual por el nuevo
            </span>
          </div>
        </td>
        <td width="10%" valign="top">
          <div style="padding-top: 10px; cursor: pointer">
            <img
              src="../../../public/assets/icon/closeModal.svg"
              v-on:click="cancel"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td width="25%" valign="top"></td>
        <td>
          <table>
            <tr>
              <td>
                <div style="padding: 15px 10px 0px 10px">
                  <span
                    class="cautionContent"
                    style="letter-spacing: 1.5px"
                    v-on:click="cancel"
                  >
                    CANCEL
                  </span>
                </div>
              </td>
              <td>
                <div style="padding: 15px 10px 0px 10px">
                  <span
                    class="cautionContent"
                    style="letter-spacing: 1.5px"
                    v-on:click="goToPayment"
                  >
                    OK
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </td>
        <td width="10%"></td>
      </tr>
    </table>
  </ion-content>
</template>

<script>
import { IonContent, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import router from "../../router/index";
import { useStore, mapMutations } from "vuex";

export default defineComponent({
  name: "ModalCambiarPlan",
  setup() {
      const modal = modalController;
      const store = useStore();
      return { 
          store,
          modal
      }
  },
  components: { IonContent },
  methods: {
    ...mapMutations("User", ["setComesFromConfig"]),
    cancel() {
      return this.modal.dismiss();
    },
    goToPayment() {
      this.setComesFromConfig(true);
      this.modal.dismiss();
      router.push("/subscriptionCurrent");
    }
  }
});
</script>

<style scoped>
table {
  text-align: left;
}
.caution {
  --background: #d46827;
}

.cautionTitle {
  color: white;
  font-family: "Avenir";
  font-size: 20px;
  letter-spacing: 1px;
}

.cautionContent {
  color: white;
  font-family: "Avenir";
  font-size: 12px;
  letter-spacing: 0.75px;
  cursor: pointer;
}
</style>

